import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import {sendGroupMessage  } from '../repository';

// Load the full build.
var _ = require('lodash');
// Load the core build.
// _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');

class MessageModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isOpen: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {

    }

    openModal = () => this.setState({ isOpen: true });
    
    closeModal = () => this.setState({ isOpen: false });

    sendMessage = () => { 
        if ( _.isEmpty(this.state.message) ) {
            alert("Message is empty.");
        } else {
            sendGroupMessage(this.state).then(response =>  {
                alert(response);
                this.setState({ isOpen: false });
            })
            .catch(e => {
                alert(e);
            });
        }
    };

    handleInputChange(event) {
        this.setState({groupId : this.props.groupId});
        this.setState({[event.target.name]: event.target.value})
    }

  render() {
    return (
        <>
        <div className="align-items-center justify-content-center" >
          <Button  className="primary-button button-green" onClick={this.openModal}>
            Message Group
          </Button>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Send Message to {this.props.groupName} Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <label>Message:</label>
                <textarea className="form-control" name="message" onChange={this.handleInputChange} cols="40" rows="5"></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className='primary-button button-green' onClick={this.sendMessage}>
              Send
            </Button>
            <Button variant="secondary" className='primary-button button-red' onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MessageModal;