import React, {Component} from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-dropdown/style.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor/dist/react-bootstrap-table2-editor';
import { updateList, isAuthenticated, getMyList, deleteMyListItems, addListItem } from '../repository';
import '../mylist.css';
import '../common.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import EditItemModal from './editItemModal.js';

// Load the full build.
//var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');

const cellEdit = cellEditFactory({
    mode: 'click',
    style: { backgroundColor: 'rgb(115, 214, 118)' },
    afterSaveCell: (oldValue, newValue, row, column) => { 
        if(newValue === '' && (column.dataField === 'price' || column.dataField === 'description')) {
            alert(column.dataField.charAt(0).toUpperCase() + column.dataField.slice(1) + ' cannot be empty');
            if(column.dataField === 'price') {
                row.price = oldValue;
            } else if(column.dataField === 'description') {
                row.description = oldValue;
            }
        } else {
            updateList(row);
        }
     }
  });

  function anyCheckbox() {
    var inputElements = document.getElementsByTagName("input");
    for (var i = 0; i < inputElements.length; i++)
        if (inputElements[i].type === "checkbox")
            if (inputElements[i].checked)
                return true;
    return false;
}

class MyList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            disabledDeleteButton: true,
            selectedItems: [],
            showModal: false,
            showEditModal: false,
            description: '',
            price: '',
            availableAt: '',
            link: '',
            size: '',
            loading: true,
            hideDescriptionMsg: true,
            hidePriceMsg: true
        };

        this.columns = [{
            dataField: 'list_Id',
            hidden: true
        }, {
            dataField: 'description',
            text: 'Description',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string',
            classes: 'handle-overflow'
        }, {
            dataField: 'price',
            text: 'Price',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)'},
            type: 'string'
        }, {
            dataField: 'available_at',
            text: 'Available At',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string',
            classes: 'handle-overflow'
        }, {
            dataField: 'link',
            text: 'Link',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string',
            classes: 'link-handle-overflow'
        }, {
            dataField: 'size',
            text: 'Size/Quantity',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            type: 'string'
        }];

        this.componentDidMount = this.componentDidMount.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.handleShowAddItemModal = this.handleShowAddItemModal.bind(this);
        this.handleHideAddItemModal = this.handleHideAddItemModal.bind(this);
        this.addItem = this.addItem.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateAddItemFields = this.validateAddItemFields.bind(this);
    }

    componentDidMount() {
        if(isAuthenticated) {
            getMyList().then(response => {
                this.setState({list: response});
            })
        }
        this.setState({loading: false})
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value})
     }

    selectRow = {
        mode: 'checkbox',
        blurToSave: true,
        hideSelectAll: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            var selectedList = this.state.selectedItems;
            if(anyCheckbox()) {
                if(isSelect) {
                    selectedList.push(row.list_Id);
                } else {
                    selectedList.pop(row.list_ID);
                }
                this.setState({selectedItems: selectedList});
                this.setState({disabledDeleteButton: false});
            } else {
                selectedList.pop(row.list_Id);
                this.setState({disabledDeleteButton: true});
            }
        }
      };

    deleteItem() {
        this.setState({loading: true})
        deleteMyListItems(this.state.selectedItems).then(response => {
            this.componentDidMount();
            this.setState({disabledDeleteButton: true});
        })
    }

    handleShowAddItemModal(){
        this.setState({showModal: true});
    }

    handleHideAddItemModal() {
        this.setState({showModal: false});
        this.setState({hideDescriptionMsg: true});
        this.setState({hidePriceMsg: true});
    }

    validateAddItemFields(description, price) {
        var message = '';
        if((description === null || description === '') && (price === null || price === '')){
            this.setState({hideDescriptionMsg: false});
            this.setState({hidePriceMsg: false});
            message = 'Error';
        } else if((description === null || description === '')) {
            this.setState({hideDescriptionMsg: false});
            this.setState({hidePriceMsg: true});
            message = 'Error';
        } else if((price === null || price === '')) {
            this.setState({hidePriceMsg: false});
            this.setState({hideDescriptionMsg: true});
            message = 'Error';
        }
        return message;
    }

    addItem() {
        this.setState({loading: true})
        var addItemElements = document.getElementsByClassName("form-control");
        var description = addItemElements[0].value;
        var price = addItemElements[1].value;
        var errMessage = this.validateAddItemFields(description, price);
        if(errMessage === '') {
            addListItem(description, price, addItemElements[2].value, addItemElements[3].value, addItemElements[4].value)
            .then(response => {
                this.handleHideAddItemModal();
                this.componentDidMount();
                this.setState({hideDescriptionMsg: true});
            this.setState({hidePriceMsg: true});
            });
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        return (
            <div >
                <Container>
                    <Row>
                        <Col>
                            <EditItemModal/>
                        </Col>
                    </Row>     
                    <Row>
                        <Col>
                        <BootstrapTable  data={this.state.list} 
                                         columns={this.columns}
                                         version='4'
                                         striped
                                         keyField='list_Id'
                                         cellEdit={cellEdit}
                                         selectRow={this.selectRow}
                                         loading={ this.state.loading }
                                         overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                                         noDataIndication={<div><b>No Items to Display</b></div>}></BootstrapTable>
                        </Col>
                    </Row>
                    <Row className="float-right">
                        <Col>
                            <Button variant='light' onClick={this.handleShowAddItemModal}
                            className='primary-button button-green'>Add New Item</Button>
                            <Button type='submit' variant='light' disabled={this.state.disabledDeleteButton}
                            onClick={this.deleteItem} className='primary-button button-red'>Delete</Button>
                        </Col>
                    </Row>
                    <Modal show={this.state.showModal} backdrop="static">
                        <Modal.Header>
                            <Modal.Title>Add New Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="description">
                                <b>Description</b><b style={{color:'red'}}>*</b> <p hidden={this.state.hideDescriptionMsg} style={{color:'red'}}>A description needs to be added</p>
                            </label>
                            <input type="text" className="form-control" id="description" name="description" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="price">
                                <b>Price</b><b style={{color:'red'}}>*</b> <p hidden={this.state.hidePriceMsg} style={{color:'red'}}>A price needs to be added</p>
                            </label>
                            <input type="text" className="form-control" id="price" name="price" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="available_at"><b>Available At</b></label>
                            <input type="text" className="form-control" id="availableAt" name="availableAt" onChange={this.handleInputChange} /><br></br>
                            <label htmlFor="link"><b>Link</b></label>
                            <input type="text" className="form-control" id="link" name="link" onChange={this.handleInputChange}/><br></br>
                            <label htmlFor="size"><b>Size\Quantity</b></label>
                            <input type="text" className="form-control" id="size" name="size" onChange={this.handleInputChange} /><br></br>
                        </Modal.Body> 
                        <Modal.Footer>
                            <Button variant="seconary" className='primary-button button-red' onClick={this.handleHideAddItemModal}>Close</Button>
                            <Button variant="primary" className='primary-button button-green' onClick={this.addItem}>Submit</Button>
                        </Modal.Footer>   
                    </Modal>
                </Container>
            </div>
        );
    }
}
export default MyList;