import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-dropdown';
import { Button } from "react-bootstrap";
import { getMyGroups, isAuthenticated, deleteGroupMember,  inviteToGroup} from '../repository';
import DeleteIcon  from '@material-ui/icons/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../common.css'

import 'react-dropdown/style.css'
import 'bootstrap/dist/css/bootstrap.css';

// Load the full build.
var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');
 
class Groups extends Component {

    constructor(props) {
        super(props);
        //console.log(this.props);

        this.state = {
            groupData : [{group_Id: '77', 
                          group_name: "BoB"}],
            selectedGroup: "",
            groups: [],
            members: [],
            filters: ["All", "Not Purchased", "Purchased"],
            selectedMember  : "",
            selectedFilter  : "",
            currentMember   : null,
            list: [],
            groupMembers: []      
    };

    function formatWithDeleteIcon(cell, row, rowIndex, formatExtraData) { 
        return ( 
                < div 
                    style={{ textAlign: "center",
                            cursor: "pointer",
                            lineHeight: "normal" }}>
    
                < DeleteIcon style={{ fontSize: 20 }}/> 
            </div> 
        ); 
    } 

        this.columns = [{
            dataField: 'member_Id',
            hidden: true
          }, {
            dataField: 'member',
            text: 'Name',
            headerStyle: { backgroundColor: 'white',textAlign: "center" },
            style: { backgroundColor: 'rgb(115, 214, 118)'},
            formatter: this.cellFormatter
          }, {
            dataField: 'emailAddress',
            text: 'Email',
            headerStyle: { backgroundColor: 'white',textAlign: "center" },
            style: { backgroundColor: 'rgb(115, 214, 118)'}
          },
          {
            dataField:'',
            text:'Delete',
            headerStyle: { backgroundColor: 'white',textAlign: "center" },
            style: { backgroundColor: 'rgb(115, 214, 118)'},
            formatter: formatWithDeleteIcon,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => { 
                    confirmAlert({
                        title: 'Confirm Deletion',
                        message: 'Are you sure want to delete member ' + row.firstName + " " + row.lastName,
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                                deleteGroupMember(this.state, row.member_Id).then(response =>  {
                                    if ( response.status === 200) {
                                        var list = this.state.list;
                                        list.splice(rowIndex,1);
                                        this.setState({list : list});
                                        
                                        //console.log(list);
                                    } else
                                    {
                                        confirmAlert({
                                            title: 'Deletion Failed',
                                            message: 'Deletion of member ' + row.firstName + " " + row.lastName + " failed.",
                                            buttons: [
                                                {
                                                  label: 'OK',
                                                  onClick: () => {}
                                                }
                                            ]
                                        })
                                        console.log("Error on delete member " + response);
                                    }  
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                            }
                          },
                          {
                            label: 'No',
                          //  onClick: () => alert('Click No')
                          }
                        ]
                      }); 
                },
            }
          },
          
        ];

        this.componentDidMount = this.componentDidMount.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.handleInputChange =this.handleInputChange.bind(this);
        this.invite =this.invite.bind(this);
    }
    
    handleInputChange(event) {
       this.setState({[event.target.name]: event.target.value})
    }

    invite = () => { 
        if (_.isEmpty(this.state.emailList)) {
            alert("At least one email is required");
        }
        else {
            inviteToGroup(this.state).then(response =>  {
                alert("Invitations sent.");  
                this.setState({emailList:""});
            })
            .catch(e => {
                alert(e);
            });
        }
    }

    componentDidMount() {
       
        if (isAuthenticated) {
            getMyGroups().then(response =>  {
                this.setState({ groupData: response});
                var groups = this.processGroups(response);
                this.setState({ groups: groups});
                this.setState({selectedGroup: groups[0]});
                this.selectGroup(groups[0]);
                //console.log(this.state.groupData);
            })
            .catch(e => {
                console.log(e);
            });
        }

    }

    cellFormatter = function(cell, row, rowIndex) {
        return (row.firstName + " " + row.lastName);
    }

    processGroups = function(response) {
        var groupDropDown = [];

        response.forEach(resp => {
             groupDropDown.push({value: resp.groupId, label: resp.groupName});
        });

        return groupDropDown;
    }

    selectGroup = function(selected) {

        var member = _.find(this.state.groupData, {'groupId' : selected.value});

        var memberDropDown = [];

        member.members.forEach(member => {
             memberDropDown.push({value: member.member_Id, label: member.firstName + " " + member.lastName} );
        });

        this.setState({selectedGroup: selected});
        this.setState({groupMembers: member.members})
        this.setState({members : memberDropDown});
        this.setState({list : member.members});

        //console.log(this.state.list);
    }

    render() {
        return (
            <div >
                <Container>
                    <Row></Row>
                    <Row>
                        <Col  md={3}>
                            <Dropdown options={this.state.groups} onChange={this.selectGroup} value={this.state.selectedGroup} placeholder="Select a Group" />
                        </Col>
                        <Col  md={3}>
                           <strong>Enter email(s) separated by commas to invite new members.</strong>
                        </Col>
                        <Col  md={3}>
                            <input type="email" className="form-control" name="emailList" value={this.state.emailList} onChange={this.handleInputChange}/>
                        </Col>
                        <Col  md={3}>
                            <Button  className="primary-button button-green" onClick={this.invite}>
                                Invite
                            </Button>
                        </Col>
                    </Row>
                    <Row>
           
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                        <BootstrapTable  data={this.state.list} 
                                         columns={this.columns} 
                                         version='4'
                                         striped
                                         hover
                                         condensed
                                         keyField='member_Id'></BootstrapTable>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
 
export default Groups;
