import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import '../common.css';

import IosInformationCircleOutline from '../../node_modules/react-ionicons/lib/IosInformationCircleOutline'

// Load the full build.
//var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');


class EditItemModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            isOpen: false
        };

        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {

    }

    openModal = () => this.setState({ isOpen: true });
    
    closeModal = () => this.setState({ isOpen: false });

    render() {
        return (
            <>
            <div className='information' onClick={this.openModal}><b>How do I edit my list?</b>          
                                <IosInformationCircleOutline></IosInformationCircleOutline>
                            </div>
            <Modal show={this.state.isOpen} backdrop="static"> 
                        <Modal.Header>
                            <Modal.Title>How to Edit My List</Modal.Title>    
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Add an Item</h3>
                                <ol>
                                    <li>Click 'Add New Item' Button</li>
                                    <li>Enter item details (don't forget to add size/quantity!)</li>
                                    <li>Click 'Submit'</li>
                                </ol>
                            <h3>Edit an Item</h3>
                                <ol>
                                    <li>Click on the cell you want to edit (text box should now appear)</li>
                                    <li>Make any necessary changes</li>
                                    <li>Hit Enter to save</li>
                                    <li>If you want to exit without saving, click anywhere outside of text box</li>
                                </ol>
                            <h3>Delete an Item</h3>
                                <ol>
                                    <li>Click the checkbox of the item(s) you want to delete</li>
                                    <li>Click 'Delete' button</li>
                                </ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className='primary-button button-red' onClick={this.closeModal}>Close</Button>
                        </Modal.Footer>      
                    </Modal>   
                </>
        );
    }
}

export default EditItemModal;