import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { logout } from  './repository';
import {  BrowserRouter as Router, Link, Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from './repository';

import 'bootstrap/dist/css/bootstrap.min.css';

import Login from './components/login';
import Register from './components/register';
import Groups from './components/groups.js';
import ManageGroups from './components/manageGroups.js';
import CreateGroup from './components/createGroup.js';
import MyPurchases from './components/myPurchases.js';
import JoinGroup from './components/joinGroup.js';
import MyList from './components/mylist.js';
import ResetPassword from './components/resetPassword.js';
import ForgotPassword from './components/forgotPassword.js';
import Header from './components/Header.js';
import PrivateRoute from './components/privateRoute';
import './App.css';

class App extends Component {
 
    state = {};

    groupData = [];
 
    render() {
        return (
          <Container>
            <Row>
              <Col>
                  <Header/>
              </Col>
            </Row>
            <Row></Row>
            <br></br>
            <Row>
              <Col>
              <Router>
                <div>
                  <nav className="navbar navbar-default">
                    <div className="container-fluid container">
                      <ul className="nav navbar-nav navbar-right navcolor">
                      {
                        ( isAuthenticated() ) ? 
                          ( <li onClick={logout}><a href="/">&nbsp;Logout</a> </li>) : 
                          ( <li><Link to="/login"  >&nbsp;Login</Link></li> )
                      }
                      </ul>
                      <ul className="nav navbar-nav">
                      { ( isAuthenticated() ) ?
                          (<li><Link to="/myPurchases">&nbsp;My Purchases</Link></li>) :
                          (<li><Link to="/register">&nbsp;Register</Link></li>)  
                      }
                      </ul>
                      {isAuthenticated() && <ul className="nav navbar-nav">
                        {   
                          (<li><Link to="/mylist">&nbsp;My List</Link></li>) 
                        }
                      </ul>}
                      <ul className="nav navbar-nav">
                        {   ( isAuthenticated() ) ? 
                            (<li><Link to="/createGroup">&nbsp;Create Group</Link></li>) : "" 
                        }
                      </ul>
                      <ul className="nav navbar-nav">
                        {   ( isAuthenticated() ) ? 
                            (<li><Link to="/groups">&nbsp;Groups</Link></li>) : "" 
                        }
                      </ul>

                      <ul className="nav navbar-nav">
                        {   ( isAuthenticated() ) ? 
                           (<li><Link to="/manageGroups">&nbsp;Manage Groups</Link></li>) : ""
                        }
                      </ul>
                    </div>
                  </nav>
                  <br></br>
                  <Route exact path="/" render={() => {
                    return (
                      (isAuthenticated() ) ?
                      <Redirect to="/groups" /> :
                      <Redirect to="/login" /> 
                    )}} />
                  <Route exact path="/register" component={Register} />
                  {/* <Route exact path="/login" render={() => {
                    return (
                      (isAuthenticated() ) ?
                      <Redirect to="/groups" /> :
                      <div></div>
                    )}} /> */}
                  <Route exact path="/joinGroup/:token" component={JoinGroup}/>
                  <Route exact path="/login" component={Login} />        
                  <Route exact path="/resetPassword/:resetToken" component={ResetPassword} />
                  <Route exact path="/forgotPassword" component={ForgotPassword} />
                  <PrivateRoute exact path="/myList" component={MyList} isAuthenticated={isAuthenticated()} isLoading={this.isLoading}/>
                  <PrivateRoute exact path="/myPurchases" component={MyPurchases} isAuthenticated={isAuthenticated()} isLoading={this.isLoading}/>
                  <PrivateRoute exact path="/createGroup" component={CreateGroup} isAuthenticated={isAuthenticated()} isLoading={this.isLoading}/>
                  <PrivateRoute exact path="/manageGroups" component={ManageGroups} isAuthenticated={isAuthenticated()} isLoading={this.isLoading}/>
                  <PrivateRoute exact path="/groups" component={Groups} isAuthenticated={isAuthenticated()} isLoading={this.isLoading}/>
                </div>
              </Router>
              </Col>
            </Row>
          </Container>
      );
    }
}

 
export default App;
