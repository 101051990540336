import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getMyPurchases, isAuthenticated, getMember, hasMember } from '../repository';
import 'react-dropdown/style.css'
import 'bootstrap/dist/css/bootstrap.css';
import '../groups.css'
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../App.css";
import "../common.css";

// Load the full build.
//var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');


//import './Groups.css';
 
class MyPurchases extends Component {

    constructor(props) {
        super();
//        console.log(this.props);

        this.state = {
            purchased: [],
            loading: true     
        };

        this.columns = [{
            dataField: 'list_Id',
            hidden: true
          }, 
          {
            dataField: 'memberName',
            text: 'Name',
            headerStyle: { backgroundColor: 'white' },
            classes: 'handle-overflow',
            style: { backgroundColor:  'rgb(115, 214, 118)' },
            sort: true,
            sortOrder: 'asc'
          },
          {
            dataField: 'description',
            text: 'Description',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            classes: 'handle-overflow',
            formatter: this.cellFormatter
          }, {
            dataField: 'price',
            text: 'Price',
            headerStyle: { backgroundColor: 'white' },
            style: { backgroundColor: 'rgb(115, 214, 118)' },
            sort:true
          }, 
        ];

        // const defaultSorted = [{
        //     dataField: 'memberName',
        //     order: 'desc'
        // }];

        this.componentDidMount = this.componentDidMount.bind(this);

    }

    componentDidMount() {
        this.setState({loading: true});
        if (isAuthenticated) {
            if (!hasMember()) {
                getMember().then(response =>  {
                    getMyPurchases().then(response =>  {
                        this.setState({ purchased: response});
                    })
                    .catch(e => {
                        console.log(e);
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            } else {
                getMyPurchases().then(response =>  {
                    this.setState({ purchased: response});
                })
                .catch(e => {
                    console.log(e);
                });
            }
        }
        this.setState({loading: false});
    }

    cellFormatter = function(cell, row, rowIndex) {
        if (row.link.startsWith("http"))
            return (<div><a className="celllink" href={row.link} target="">{cell}</a></div>);
        else
            return (<div>{cell}</div>);
    }

    purchasedCellFormatter = function(cell, row, rowindex) {
        let currentMember = JSON.parse(localStorage.getItem('member'));
        if(row.purchased === "0")
            return (<div><button className="primary-button button-green">Bought It</button></div>);
        else if(row.purchased === "1" && row.purchasedBy === currentMember.member_Id)
            return (<div><button className="primary-button button-red">Un-Buy</button></div>);
        else if(row.purchased === "1")
            return(<div>Purchased</div>);
    }

    render() {
        return (
            <div >
                <Container>
                    <Row></Row>
                    <Row>
           
                    </Row>
                    <br></br>
                    <Row>
                        <Col>
                        <BootstrapTable  data={this.state.purchased} 
                                         columns={this.columns} 
                                         version='4'
                                         striped
                                         hover
                                         condensed
                                         keyField='list_Id'
                                         loading={ this.state.loading }
                                         overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) }
                                         noDataIndication={<div><b>No Items to Display</b></div>}></BootstrapTable>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
 
export default MyPurchases;
