import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom';

import { login } from '../repository';

// Load the full build.
var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');

class Login extends Component {
    constructor() {
    super();
    this.state = { email: '', password: '' };
    this.handleInputChange =this.handleInputChange.bind(this);
    this.submitLogin =this.submitLogin.bind(this);
}

handleInputChange(event) {
  this.setState({[event.target.name]: event.target.value})
}

submitLogin(event){
  if ( _.isEmpty(this.state.email) || _.isEmpty(this.state.password) ) {
     alert("Email and Password are required");
  }
  else {
    event.preventDefault();
    login(this.state)
    .then(token => window.location = '/')
    .catch(err => alert(err));
 }
}

render() {
 return (
 <Container>
 <hr/>
    <Row>
        <Col md={4}></Col>
        <Col>
            <h3>Log in </h3>
        </Col>
        <Col md={4}></Col>
    </Row>

        <form onSubmit={this.submitLogin}>
        <Row >
            <Col md={4}></Col>
            <Col>
                <label>UserID/Email:</label>
                <input type="email" className="form-control" name="email" onChange={this.handleInputChange}/>
            </Col>
            <Col md={4}></Col>
        </Row>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                <label>Password:</label>
                <input type="password" className="form-control" name="password" onChange={this.handleInputChange}/>
                </Col>
            <Col md={4}></Col>
        </Row>
        <Row>
        <Col> <br></br> </Col> 
        </Row>
        <Row>
            <Col md={4}></Col>
            <Col md={2}>
                 <button type="submit" className="primary-button button-green">Submit</button>
            </Col>
            <Col md={2}><Link to="/forgotPassword">&nbsp;Forgot Password  </Link></Col>
        </Row>
        </form>
 </Container>
 );
 }
}
 export default Login;