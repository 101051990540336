import React, {Component} from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { joinGroupReset } from '../repository';
import 'react-dropdown/style.css'
import 'bootstrap/dist/css/bootstrap.css';

// Load the full build.
var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');


//import './Groups.css';
 
class JoinGroup extends Component {

    constructor(props) {
        super();
        //console.log(this.props);

        this.state = { firstName: '', lastName: '', password: '', retypePassword: '', token: '' };
        this.handleInputChange =this.handleInputChange.bind(this);
        this.submitCompleteJoin =this.submitCompleteJoin.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        // this.selectGroup = this.selectGroup.bind(this);
        // this.selectMember = this.selectMember.bind(this);
        // this.selectFilter = this.selectFilter.bind(this);
    }

    componentDidMount() {
        this.setState({ token:`${this.props.match.params.token}`});
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    submitCompleteJoin(event){
        if ( _.isEmpty(this.state.retypePassword) || _.isEmpty(this.state.password) ) {
            alert("Both passwords are required");
        }
        else {
            if(this.state.password === this.state.retypePassword) {
                event.preventDefault();
                joinGroupReset(this.state)
                    .then(token => window.location = '/')
                    .catch(err => alert(err));
            } else {
                alert("Passwords do not match.");
            }
        }
    }

    render() {
        return (
        <Container>
           
            <Row></Row>
            <br></br>
            <Row>
                <Col md={4}></Col>
              <Col>
                   <h3>Complete Signup</h3>
               </Col>
               <Col md={4}></Col>
            </Row>
            <div className="panel-body">
            <form onSubmit={this.submitCompleteJoin}>
            <Row>
                <Col md={3}></Col>
                <Col>
                <label>First Name:</label>
                <input type="text" className="form-control" name="firstName" onChange={this.handleInputChange}/>
                </Col>
                <Col md={3}></Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col>
                       <label>Last Name:</label>
                       <input type="text" className="form-control" name="lastName" onChange={this.handleInputChange}/>
                </Col>
                <Col md={3}></Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col>
                       <label>Password:</label>
                       <input type="password" className="form-control" name="password" onChange={this.handleInputChange}/>
                </Col>
                <Col md={3}></Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col>
                       <label>Confirm Password:</label>
                       <input type="password" className="form-control" name="retypePassword" onChange={this.handleInputChange}/>
                </Col>
                <Col md={3}></Col>
            </Row>
            <Row>
                <Col md={3}></Col>
                <Col>
                   <button type="submit" className="primary-button button-green ">Submit</button>
                </Col>
                <Col md={3}></Col>
            </Row>
            </form>
            </div>
        </Container>    
        );
    }
}
 
export default JoinGroup;
