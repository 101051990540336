import axios from 'axios';

//const { REACT_APP_MY_ENV } = process.env;
//const { REACT_APP_API_URL } = process.env;

//const BASE_URL = API_URL = NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;
//const BASE_URL = 'http://localhost:7000';
// Dev
//const BASE_URL = 'https://devwishlist.webahar.com/dev-wishlist-0.0.1-SNAPSHOT';
//const BASE_URL = 'https://api.webahar.com/wishlist-1.0.0-SNAPSHOT';
//Prod
const BASE_URL = 'https://devwishlist.webahar.com/wishlist-1.0.1-SNAPSHOT';

const config = {
     headers: {
          'get': {
               Accept: 'application/json',
               'Content-Type': 'application/json',
               authorization: 'Bearer ' + localStorage.getItem('x-access-token') 
          }
     }
}

const postConfig = {
     headers: {
          'post': {
               Accept: 'application/json',
               'Content-Type': 'application/json',
               authorization: 'Bearer ' + localStorage.getItem('x-access-token') 
          }
     }
}


export function getGroups () {

     return axios.get(`${BASE_URL}/groups`, config)
          .then(response => response.data)
          .catch(err =>  {
               if ( err.status === 401 && err.error === "Unauthorized") { 
                    Promise.reject('Authentication Failed!');
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }
          });
}


export function getGroupsByMember () {
     let member = JSON.parse(localStorage.getItem('member'));

     if (member == null)
     {
          alert("No Member!");
          return false;
     }   

     return axios.get(`${BASE_URL}/groups/member/` + member.member_Id, config)
          .then(response => response.data)
          .catch(err =>  {
               if ( err.status === 401 && err.error === "Unauthorized") { 
                    Promise.reject('Authentication Failed!');
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }
          });
}


export function getMyGroups () {
     let member = JSON.parse(localStorage.getItem('member'));

     if (member == null)
     {
          alert("No Member!");
          return false;
     }   

     return axios.get(`${BASE_URL}/groups/admin/` + member.member_Id, config)
          .then(response => response.data)
          .catch(err => {
               if ( err.status === 401 && err.error === "Unauthorized") { 
                    Promise.reject('Authentication Failed!');
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }
          });
}


export function getMyPurchases () {
     let member = JSON.parse(localStorage.getItem('member'));

     if (member == null)
     {
          alert("No Member!");
          return false;
     }   

     return axios.get(`${BASE_URL}/lists/myPurchases/` + member.member_Id, config)
          .then(response => response.data)
          .catch(err => {
               if ( err.status === 401 && err.error === "Unauthorized") { 
                    Promise.reject('Authentication Failed!');
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }
          });
}


export function deleteGroupMember (data, member_Id) {
     let config = {
          headers: {
               'delete': {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + localStorage.getItem('x-access-token') 
               }
          }
     }

     return axios.delete(`${BASE_URL}/groups/member/delete/` + data.selectedGroup.value + "/" + member_Id, config)
          .then(response => response)
          .catch(err => {
               if ( err.response.data.status === 401 && err.response.data.error === "Unauthorized") { 
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }

               return {
                        status: err.response.data.status,
                        message: err.response.data.message 
               };
          });
}

export function createGroup (data) {
     let config = {
          headers: {
               'post': {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + localStorage.getItem('x-access-token') 
               }
          }
     }

     let member = JSON.parse(localStorage.getItem('member'));

     return axios.post(`${BASE_URL}/groups/create`, { group_admin_id : member.member_Id,
                                                      groupName: data.groupName, 
                                                      group_password: data.groupPassword, 
                                                      emailList: data.emailList }, config)
     .then(response => {
        alert("Group was created.");  
        return response.data 
     })
     .catch(err => { 
          if ( err.status === 401) { 
               Promise.reject('Authentication Failed!');
               localStorage.removeItem('x-access-token');
               localStorage.removeItem('x-access-token-expiration');
               localStorage.removeItem('member');
               localStorage.removeItem('member-expiration');
               localStorage.removeItem('username');
               localStorage.removeItem('username-expiration');
          } else {
               alert(err.response.data.message);
          }
     });
}

export function inviteToGroup (data) {

     let member = JSON.parse(localStorage.getItem('member'));

     return axios.post(`${BASE_URL}/groups/invite`, { groupId: data.selectedGroup.value,
                                                      groupName: data.selectedGroup.label,
                                                      group_admin_id : member.member_Id,
                                                      emailList: data.emailList }, postConfig)
     .then(response => {
        return response; 
     })
     .catch(err => { 
        return err;
     });
}

export function sendGroupMessage (data) {

     let member = JSON.parse(localStorage.getItem('member'));

     return axios.post(`${BASE_URL}/sendMessage`, { groupId : data.groupId,
                                                    fromMemberName: member.firstName + " " + member.lastName, 
                                                    message: data.message}, postConfig)
                    .then(response => {
                         return "Message was sent." 
                    })
                    .catch(err => { 
                         return "Send message failed."
                    });
}

export function joinGroupReset (data) {
     return axios.post(`${BASE_URL}/resetPasswordJoin`, { firstName: data.firstName, 
                                                          lastName: data.lastName, 
                                                          emailAddress: data.emailAddress,
                                                          password: data.password,
                                                          token:  data.token})
          .then(response => {
                    localStorage.setItem('x-access-token', response.data.token);
                    localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);

                    localStorage.setItem('member', JSON.stringify(response.data));
                    localStorage.setItem('member-expiration', Date.now() + 2 * 60 * 60 * 1000);

                    localStorage.setItem('username', data.name);
                    localStorage.setItem('username-expiration', Date.now() + 2 * 60 * 60 * 1000);
                    return response.data
               })
          .catch(err => Promise.reject('Registration Failed!'));
          
}

export function login (data) {
 return axios.post(`${BASE_URL}/authenticate`, { username: data.email, password: data.password })
 .then(response => {
    localStorage.setItem('x-access-token', response.data.token);
    localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);

    localStorage.setItem('username', data.email);
    localStorage.setItem('username-expiration', Date.now() + 2 * 60 * 60 * 1000);
    return response.data 
 })
 .catch(err => Promise.reject('Authentication Failed!'));
}


export function forgotPassword (data) {
     return axios.post(`${BASE_URL}/forgotPassword`, { user_Id: data.name })
          .then(response => {
          return response.data 
          })
          .catch(err =>{
               if ( err.status === 401) { 
                    Promise.reject('There was a problem with your request.');
               } else {
                    alert(err.response.data.message);
               }    
          });
}


export function resetPassword (data) {
     return axios.post(`${BASE_URL}/resetPassword`, { token: data.resetToken,
                                                      password: data.password })
          .then(response => {
          return response.data 
          })
          .catch(err =>{
               if ( err.status === 401) { 
                    Promise.reject('There was a problem with your request.');
               } else {
                    alert(err.response.data.message);
               }
          });
}

export function validatePasswordReset (data) {
     return axios.post(`${BASE_URL}/validatePasswordReset`, { token: data })
          .then(() => {
             return true; 
          })
          .catch(() =>{
             return false;  
          });
}

export function register (data) {
     return axios.post(`${BASE_URL}/register`, { firstName: data.firstName, 
                                                  lastName: data.lastName, 
                                                  emailAddress: data.emailAddress,
                                                  email_notification: "N",
                                                  user_Id: data.emailAddress, 
                                                  password: data.password })
     .then(response => {
        localStorage.setItem('x-access-token', response.data.token);
        localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);

        localStorage.setItem('username', data.name);
        localStorage.setItem('username-expiration', Date.now() + 2 * 60 * 60 * 1000);
        return response.data
     })
     .catch(err => Promise.reject('Registration Failed!'));
}

export function logout () {
     localStorage.removeItem('x-access-token');
     localStorage.removeItem('x-access-token-expiration');
     localStorage.removeItem('member');
     localStorage.removeItem('member-expiration');
     localStorage.removeItem('username');
     localStorage.removeItem('username-expiration');
}

export function isAuthenticated(){
     return localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now()
}

export function getMember() { 
     if (localStorage.getItem('username') && localStorage.getItem('username-expiration') > Date.now()) {
          return axios.get(`${BASE_URL}/members/` + localStorage.getItem('username'), config)
          .then(response => {
               localStorage.setItem('member', JSON.stringify(response.data));
               localStorage.setItem('member-expiration', Date.now() + 2 * 60 * 60 * 1000);

               return true;
          })
          .catch(err => { 
               if ( err.status === 401) { 
                    Promise.reject('Authentication Failed!');
                    localStorage.removeItem('x-access-token');
                    localStorage.removeItem('x-access-token-expiration');
                    localStorage.removeItem('member');
                    localStorage.removeItem('member-expiration');
                    localStorage.removeItem('username');
                    localStorage.removeItem('username-expiration');
               } else {
                    alert(err.response.data.message);
               }
     
          });
     }
     else
        return false;
        
}

export function hasMember(){
     return localStorage.getItem('member') && localStorage.getItem('member-expiration') > Date.now()
}

export function getMyList(){
     let member = JSON.parse(localStorage.getItem('member'));
     return axios.get(`${BASE_URL}/lists/memberlist/` + member.member_Id, config)
          .then(response => response.data)
          .catch(err => Promise.reject('Request Not Authenticated!'));
}

export function updateList(list) {
     let member = JSON.parse(localStorage.getItem('member'));
     return axios.post(`${BASE_URL}/lists/updatelist/` + member.member_Id, { member_Id: member.member_Id, 
                                                                           list_Id: list.list_Id,
                                                                           description: list.description,
                                                                           price: list.price,
                                                                           available_at: list.available_at,
                                                                           size: list.size,
                                                                           link: list.link,
                                                                           purchased: list.purchased,
                                                                           purchasedBy: list.purchasedBy}, postConfig)
               .then(response => response.data)
               .catch(err => Promise.reject('Request Not Authenticated!'));
}

export function deleteMyListItems(listIds) {
     let member = JSON.parse(localStorage.getItem('member'));
     var myResponse = null;
     listIds.forEach(id => {
          myResponse = axios.post(`${BASE_URL}/lists/deletelistitems/` + member.member_Id, {list_Id: id},
                                                                      postConfig)
                                                                      .then(response => response.data)
                                                                      .catch(err => Promise.reject('Request Not Authenticated!'))});
     return myResponse;
}

export function addListItem(description, price, availableAt, link, size) {
     let member = JSON.parse(localStorage.getItem('member'));
     let response = axios.post(`${BASE_URL}/lists/addlistitem/` + member.member_Id, 
                                                       {member_Id: member.member_Id,
                                                       description: description,
                                                       price: String(price),
                                                       available_at: availableAt,
                                                       link: link,
                                                       size: String(size)}, postConfig)
                                                       .then(response => response.data)
                                                       .catch(err => Promise.reject('Request Not Authenticated!'));
     return response;
                                        
}

export function updatePurchased(listId, purchased) {
     let member = JSON.parse(localStorage.getItem('member'));
     let response = axios.post(`${BASE_URL}/lists/markaspurchased/` + member.member_Id, 
                                                       {list_Id: listId, purchased: purchased, purchasedBy: member.member_Id}, postConfig)
                                                       .then(response => response.data)
                                                       .catch(err => Promise.reject('Request Not Authenticated!'));
     return response;
}