import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {  Link } from 'react-router-dom';

import { resetPassword } from '../repository';
import { validatePasswordReset } from '../repository';

// Load the full build.
var _ = require('lodash');
// Load the core build.
//var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
//var fp = require('lodash/fp');

class Login extends Component {
    constructor() {
    super();
    this.state = { name: '', password: '' };
    this.handleInputChange =this.handleInputChange.bind(this);
    this.submitResetPassword =this.submitResetPassword.bind(this);
}

handleInputChange(event) {
    this.setState({[event.target.name]: event.target.value})
}

submitResetPassword(event) {
    if ( _.isEmpty(this.state.retypePassword) || _.isEmpty(this.state.password) ) {
        alert("Both passwords are required");
    }
    else {
        if (this.state.password === this.state.retypePassword) {
            event.preventDefault();
                resetPassword(this.state)
                    .then(token => window.location = '/')
                    .catch(err => alert(err));
        } else {
            alert("Passwords do not match.");
        }
    }
}


async componentDidMount() {
    this.setState({"resetToken": this.props.match.params.resetToken});
    const tokenValid = await validatePasswordReset(`${this.props.match.params.resetToken}`);
    this.setState({"tokenValid": tokenValid});
}


render() {
 return (
    <Container>
        <hr/>
        <Row>
            <Col md={4}></Col>
            <Col>
                <h3>Change Password </h3>
            </Col>
            <Col md={4}></Col>
        </Row>
        { this.state.tokenValid ? 
            <form onSubmit={this.submitResetPassword}>
            <Row >
                <Col md={4}></Col>
                <Col md={4}>
                    <label>Password:</label>
                    <input type="password" className="form-control" name="password" onChange={this.handleInputChange}/>
                    </Col>
                <Col md={4}></Col>
            </Row>
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <label>Retype Password:</label>
                    <input type="password" className="form-control" name="retypePassword" onChange={this.handleInputChange}/>
                    </Col>
                <Col md={4}></Col>
            </Row>
            <Row>
            <Col> <br></br> </Col> 
            </Row>
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <button type="submit" className="primary-button button-green ">Submit</button>
                </Col>
                <Col md={4}></Col>
            </Row>
            </form>
        :
            <Row >
                <Col md={4}></Col>
                <Col md={4}>
                    <label>Reset Expired  </label>
                    <Link to="/forgotPassword"> Forgot Password  </Link>
                    </Col>
                <Col md={4}></Col>
            </Row>
        }
    </Container>
);
}
}
export default Login;